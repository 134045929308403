import React, {useEffect, useState} from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import {fontFamily} from "./theme.js";
import {media} from "./theme.js";
import EditableComponent from "./editable-component.js";
import {shadow} from "./theme.js";
import Stack from "./stack.js";
import {withAttrs} from "../common/utils.js";
import {Title} from "./typo.js";
import {Routes} from "../typings/routes.js";
import {useFetch} from "../common/hooks.js";
import {getUrl} from "../common/universal.js";
import Input from "./input.js";
import Button from "./button.js";
import request from "../common/request.js";
export default function PageNewsletter(props) {
  const {
    headline,
    notSubscribedText,
    inputPlaceholder,
    buttonText,
    alreadySubscribedText
  } = props;
  const [resp] = useFetch(getUrl(Routes.getNewsletterStatus));
  const [isSubscribed, setIsSubscribed] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const email = formData.get("email");
    try {
      await request(getUrl(Routes.subscribeNewsletter), {
        method: "POST",
        body: {email}
      });
      setIsSubscribed(true);
    } catch (error) {
      console.error(error);
    }
  };
  let loggedIn = false;
  if (resp.type === "success") {
    loggedIn = resp.value.loggedIn;
  }
  useEffect(() => {
    if (resp.type === "success") {
      setIsSubscribed(resp.value.subscribed);
    }
  }, [resp]);
  return /* @__PURE__ */ React.createElement(EditableComponent, {
    ...props
  }, /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(Headline, null, headline), isSubscribed ? /* @__PURE__ */ React.createElement(Text, null, alreadySubscribedText) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, null, notSubscribedText), /* @__PURE__ */ React.createElement(InputContainer, {
    onSubmit: handleSubmit
  }, !loggedIn && /* @__PURE__ */ React.createElement(Input, {
    name: "email",
    type: "email",
    required: true,
    placeholder: inputPlaceholder
  }), /* @__PURE__ */ React.createElement(Button, {
    type: "submit"
  }, buttonText))))));
}
const Container = styled.div`
  padding: 40px 56px;
  box-shadow: ${shadow.card};
  border-radius: 4px;
  overflow: hidden;
  max-width: 1024px;
  margin: 100px auto 0;

  ${media.phone} {
    padding: 16px;
  }
`;
const Inner = styled(withAttrs(Stack, {spacing: "m"}))`
  ${media.phone} {
    --spacing: 8px;
  }
`;
const Headline = styled(withAttrs(Title, {level: 2}))`
  font-size: 30px;

  ${media.phone} {
    font-size: 24px;
  }
`;
const Text = styled.div`
  font-family: ${fontFamily.primary};
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  ${media.phone} {
    font-size: 16px;
    line-height: 24px;
  }
`;
const InputContainer = styled.form`
  display: flex;
  gap: 16px;
  margin-top: 8px;

  ${media.phone} {
    flex-direction: column;
  }
`;
