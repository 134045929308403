import React from "../_snowpack/pkg/react.js";
import styled from "../_snowpack/pkg/@emotion/styled.js";
import Image from "./image.js";
export default function HeroImage({image}) {
  if (!image) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(ImageContainer, {
    ...image
  });
}
const ImageContainer = styled(Image)`
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
`;
