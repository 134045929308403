import {t} from "../common/i18n.js";
import request from "../common/request.js";
import createStore from "../common/store.js";
import {getUrl} from "../common/universal.js";
import {confirm} from "../components/confirm.js";
import {Routes} from "../typings/routes.js";
const store = createStore();
export const CartProvider = store.Provider;
export function useCartStore() {
  const [cart, setCart] = store.useStore();
  async function addCoupon(code) {
    const cart2 = await request(getUrl(Routes.cartAddCouponPost), {
      method: "post",
      body: {code}
    });
    setCart(cart2);
  }
  async function removeCoupon(id) {
    const cart2 = await request(getUrl(Routes.cartRemoveCouponPost), {
      method: "post",
      body: {id}
    });
    setCart(cart2);
  }
  async function removeItem(id) {
    if (!await confirm({
      message: t("Diesen Artikel aus dem Warenkorb entfernen?"),
      okText: t("Entfernen")
    })) {
      return;
    }
    const cart2 = await request(getUrl(Routes.cartRemoveItem), {
      method: "post",
      body: {id}
    });
    setCart(cart2);
  }
  async function updateItem(itemId, body) {
    const updateCartItemUrl = getUrl(Routes.cartUpdateItem, {
      item_id: itemId
    });
    const cart2 = await request(updateCartItemUrl, {
      method: "put",
      body
    });
    setCart(cart2);
  }
  return {
    cart,
    setCart,
    addCoupon,
    removeItem,
    updateItem,
    removeCoupon
  };
}
