import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import Contact from "./contact.js";
import ImageText from "./image-text.js";
import ClientList from "./client-list.js";
import Steps from "./steps.js";
import ContentCards from "./content-cards.js";
import Process from "./process.js";
import PageContact from "./page-contact.js";
import Doc from "./doc.js";
import {colors, media} from "./theme.js";
import Button from "./button.js";
import Stack from "./stack.js";
import ImageRichText from "./image-richtext.js";
import ProductGrid from "./product-grid.js";
import HeroImage from "./hero-image.js";
export default function ContentModules(props) {
  const {modules, isProductPage, isAboutPage, ...rest} = props;
  if (!modules)
    return null;
  const ImageTextModifier = isAboutPage || isProductPage;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, modules.map((module) => {
    const key = module._uid;
    if (module.component === "steps") {
      return /* @__PURE__ */ React.createElement(Steps, {
        key,
        ...module,
        ...rest,
        isProductPage
      });
    }
    if (module.component === "hero_image") {
      return /* @__PURE__ */ React.createElement(HeroImage, {
        key,
        ...module,
        ...rest
      });
    }
    if (module.component === "image_text") {
      return /* @__PURE__ */ React.createElement(ImageText, {
        key,
        ...module,
        ...rest,
        isProductPage: ImageTextModifier
      });
    }
    if (module.component === "image_richtext") {
      return /* @__PURE__ */ React.createElement(ImageRichText, {
        key,
        ...module,
        ...rest
      });
    }
    if (module.component === "product_grid") {
      return /* @__PURE__ */ React.createElement(ProductGrid, {
        key,
        ...module,
        isProductPage,
        ...rest
      });
    }
    if (module.component === "client_list") {
      return /* @__PURE__ */ React.createElement(ClientList, {
        key,
        ...module,
        ...rest
      });
    }
    if (module.component === "contact") {
      return /* @__PURE__ */ React.createElement(Contact, {
        key,
        ...module,
        ...rest,
        isProductPage
      });
    }
    if (module.component === "content_cards") {
      return /* @__PURE__ */ React.createElement(ContentCards, {
        key,
        ...module,
        ...rest
      });
    }
    if (module.component === "process") {
      return /* @__PURE__ */ React.createElement(Process, {
        key,
        ...module,
        ...rest,
        isProductPage
      });
    }
    if (module.component === "richtext") {
      if (!module.content)
        return null;
      return /* @__PURE__ */ React.createElement(DocContainer, {
        key,
        ...module.content
      });
    }
    if (module.component === "cta_module") {
      if (!module.Text)
        return null;
      return /* @__PURE__ */ React.createElement(Stack, {
        key
      }, /* @__PURE__ */ React.createElement(CtaModule, {
        align: module?.alignment
      }, /* @__PURE__ */ React.createElement(Button, {
        href: module.link.cached_url
      }, /* @__PURE__ */ React.createElement("span", null, module.Text))));
    }
    if (module.component === "page_contact") {
      return /* @__PURE__ */ React.createElement(PageContact, {
        key,
        ...module,
        ...rest,
        isProductPage
      });
    }
    return null;
  }));
}
const DocContainer = styled(Doc)`
  padding: 30px 0;

  h1,
  h2 {
    margin-bottom: 1em;
    color: ${colors.b};
  }

  // h1 {
  //   font-size: 24px;
  // }

  // h2 {
  //   font-size: 20px;
  // }

  b {
    color: ${colors.b};
  }

  p {
    color: ${colors.b2};
    // margin-top: 1em;
    // margin-bottom: 1em;
    min-height: 1em;
  }

  blockquote {
    margin-left: 1.5em;
  }

  ul li {
    list-style: disc;
    margin-left: 1.5em;
  }

  ol li {
    list-style: decimal;
    margin-left: 1.5em;
  }

  ${media.phone} {
    padding-top: 24px;
    padding-bottom: 0;

    h1 {
      font-size: 20px;
    }

    h2 {
      font-size: 16px;
    }
  }
`;
const CtaModule = styled.div`
  display: flex;
  justify-content: ${(props) => {
  const {align} = props;
  if (align === "center")
    return "center";
  if (align === "right")
    return "flex-end";
  return "flex-start";
}};

  margin: var(--spacing) 0;
`;
