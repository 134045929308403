import styled from "../_snowpack/pkg/@emotion/styled.js";
import React from "../_snowpack/pkg/react.js";
import {Title} from "./typo.js";
import {media} from "./theme.js";
import ProductCard from "./product-card.js";
import Component from "./editable-component.js";
import Stack from "./stack.js";
export default function ProductGrid(props) {
  const {headline, rows, isProductPage, ...rest} = props;
  return /* @__PURE__ */ React.createElement(Component, {
    ...rest
  }, /* @__PURE__ */ React.createElement(Container, {
    id: "products",
    isProductPage
  }, /* @__PURE__ */ React.createElement(Headline, {
    level: 2,
    isProductPage
  }, headline), rows && /* @__PURE__ */ React.createElement(Rows, null, rows.map((row) => /* @__PURE__ */ React.createElement(Row, {
    key: row._uid,
    cols: row.items.length,
    hasHighlight: row.items.some((p) => !!p.highlightText)
  }, row.items.map((product) => /* @__PURE__ */ React.createElement(ProductCard, {
    key: product._uid,
    small: row.items.length > 2,
    ...product
  })))))));
}
const Container = styled.div`
  ${({isProductPage}) => !isProductPage && `
  padding: 50px 0 0;

  ${media.tablet} {
    padding: 48px 0 0;
  }

  ${media.desktop} {
    padding: 50px 0;
  }
  `}

  padding: 0;
`;
const Rows = styled(Stack)`
  --spacing: 24px;
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(${({cols}) => cols}, 1fr);
  grid-gap: 24px;
  width: 100%;

  ${media.phone} {
    grid-template-columns: 1fr;
  }

  ${({hasHighlight}) => hasHighlight && `
    margin-bottom: 24px;
  `}
`;
const Headline = styled(Title)`
  font-size: 46px;
  line-height: 54px;
  margin-bottom: 56px;
  grid-area: Headline;

  ${({isProductPage}) => isProductPage && `
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 42px;
  `}

  ${media.mobile} {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
`;
